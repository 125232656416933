import {eventManager} from '@application/Event';
import {UiEvent, UserEvent} from '@domain/model/Event';
import {PageEventView, PageViewEventPayload} from '@domain/model/Event/domain/model/UiEvents';
import {getAuthHeader} from '@infra/api/authManager/authManager';
import {emitUserEvent, initTracking, setTrackingUserId, unsetTrackingUserId} from '@lookiero/user-tracking-front';
import {DIRECT_BUY_EVENT} from '@lookiero/direct-buy-front';

import {
  APP_ACTIONS,
  DIRECT_BUY_ACTIONS,
  EXPERIMENT_ACTIONS,
  HOME_VIEWS,
  MY_LOOKIERO_EVENTS,
  NAVIGATION_ACTION_TO_NAME,
  NAVIGATION_ACTIONS,
  PAY_ACTIONS,
  PROMO_CODE_ACTIONS,
  SESSION_ACTION_TO_NAME,
  SESSION_ACTIONS,
  TRANSACTIONAL_ACTION_TO_NAME,
  TRANSACTIONAL_ACTIONS,
  VIEW_TO_SECTION,
} from './tracking.definition';
import {DomainEvent} from '../typings/domainEvent';
import {
  cleanUserData,
  getPaymentInstrument,
  getStore,
  getUserData,
  initUserData,
  isLogged,
  UserDataFields,
} from '@tracking/user-data';
import {AuthApplication} from '@application/Auth';

const getUserParams = async (): Promise<{userId: string; segment: string} | Record<string, never>> => {
  const logged = await isLogged();
  if (logged) {
    const {id: userId, segment} = await getUserData();
    return {userId: userId as string, segment: segment as string};
  }
  return {};
};

const getEvent = (
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {promoCode, isFirstOrder, deliveryMethod = '', daysSkipped, ...others}: any,
): Record<string, unknown> => {
  return {
    ecommerce: {
      [type]: {
        actionField: {
          coupon: promoCode,
          ...others,
        },
      },
    },
    isFirstOrder,
    daysSkipped,
    deliveryMethod,
  };
};
type SubscriptionPausedPayload = {daysPaused: number};

const PAYLOAD_TO_EVENT: Record<string, (payload: unknown) => Record<string, unknown>> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.ORDER_CANCELLED]: (payload: any) => getEvent('cancel', payload),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.ORDER_PLACED]: (payload: any) => getEvent('purchase', payload),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.ORDER_SKIPPED]: (payload: any) => getEvent('orderSkipped', payload),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.SUBSCRIPTION_CANCELLED]: (payload: any) => getEvent('cancelSubscription', payload),
  [UserEvent.SUBSCRIPTION_PAUSED]: (payload: unknown) => ({
    daysSkipped: (payload as SubscriptionPausedPayload).daysPaused,
  }),
};

const {userTracker, sendTrackerModel} = initTracking();

eventManager.subscribe(
  UiEvent.PAGE_LOAD,
  async ({view, category, ...payloadExtraInfo}: PageViewEventPayload): Promise<void> => {
    const section = VIEW_TO_SECTION[view];
    if (section) {
      const store = await getStore();
      const paymentInstrument = await getPaymentInstrument();
      const userLogged = await isLogged();

      const eventData = {
        paymentMethod: paymentInstrument != null ? paymentInstrument.payment_method : 'none',
        paymentRegistered: paymentInstrument ? 'true' : 'false',
        section: category ? `${category}_${section}` : section,
        store,
        userLogged,
        ...(await getUserParams()),
        ...payloadExtraInfo,
      };

      userTracker(eventData);
    }
  },
);

SESSION_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    const isLogout = action === UserEvent.LOGOUT;
    const isLogin = action === UserEvent.LOGIN;
    const isRegister = action === UserEvent.REGISTER;

    let eventData = {
      event: SESSION_ACTION_TO_NAME[action],
      eventCategory: 'account',
      userLogged: action !== UserEvent.LOGOUT,
      ...payload,
    };

    if (isLogin || isRegister) {
      const {loginMethod} = await AuthApplication.getLoginInfo();
      const {id} = await getUserData();

      setTrackingUserId(id);
      eventData = {
        ...eventData,
        accessVia: loginMethod,
        ...(await getUserParams()),
      };
    } else if (isLogout) {
      unsetTrackingUserId();
    }
    emitUserEvent({...eventData});
  });
});

TRANSACTIONAL_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    const paymentInstrument = await getPaymentInstrument();

    const extra =
      action === UserEvent.ORDER_PLACED
        ? {
            paymentMethod: paymentInstrument != null ? paymentInstrument.payment_method : 'none',
          }
        : {};

    const eventData = {
      event: TRANSACTIONAL_ACTION_TO_NAME[action],
      eventCategory: 'ecommerce',
      ...(await getUserParams()),
      ...PAYLOAD_TO_EVENT[action](payload),
      ...extra,
    };
    emitUserEvent(eventData);
  });
});

interface MyLookieroEventPayload {
  section?: string;
  view: PageEventView;
  [x: string]: unknown;
}

MY_LOOKIERO_EVENTS.forEach(event =>
  eventManager.subscribe(event, async (payload: MyLookieroEventPayload) => {
    const [, eventName] = event.split('.');
    const store = await getStore();
    const {view, ...rest} = payload;
    const section = rest.section || VIEW_TO_SECTION[view];

    userTracker({
      event: eventName,
      eventCategory: 'navigation',
      userLogged: true,
      section,
      store,
      ...(await getUserParams()),
      ...rest,
    });
  }),
);

eventManager.subscribe(
  UserEvent.SUBSCRIPTION_UPDATED,
  async ({subscription, newSubscription, previousFrequencyDay, currentFrequencyDay}) => {
    if (subscription !== newSubscription) {
      emitUserEvent({
        event: 'updateSubscription',
        eventCategory: 'ecommerce',
        subscription,
        newSubscription,
        ...(await getUserParams()),
      });
    }
    if ((currentFrequencyDay || previousFrequencyDay) && currentFrequencyDay !== previousFrequencyDay) {
      emitUserEvent({
        event: 'recurrentDeliveryChanged',
        eventCategory: 'ecommerce',
        previousDay: previousFrequencyDay,
        newDay: currentFrequencyDay,
        ...(await getUserParams()),
      });
    }
  },
);

NAVIGATION_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    const eventData = {
      event: NAVIGATION_ACTION_TO_NAME[action],
      eventCategory: 'navigation',
      ...(await getUserParams()),
      ...payload,
    };
    emitUserEvent(eventData);
  });
});

eventManager.subscribe(UiEvent.PAGE_LOAD, async ({view}: PageViewEventPayload): Promise<void> => {
  if (HOME_VIEWS.includes(view)) {
    // voyager y pathfinder
    sendTrackerModel({...(await getAuthHeader())});
  }
});

EXPERIMENT_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    const eventData = {
      event: action,
      eventCategory: 'experiment',
      ...(await getUserParams()),
      ...payload,
    };
    emitUserEvent(eventData);
  });
});

const DIRECT_BUY_EVENTS_TO_SEND_DATA: string[] = [
  DIRECT_BUY_EVENT.CHECKOUT_SOLD_OUT,
  DIRECT_BUY_EVENT.COMPLETE_YOUR_LOOK_SELECTED,
  DIRECT_BUY_EVENT.CATEGORIES_SELECTED,
  DIRECT_BUY_EVENT.CATEGORIES_LOAD,
  DIRECT_BUY_EVENT.CATEGORY_TAB_SELECTED,
  DIRECT_BUY_EVENT.WISHLIST_ITEM_ADDED,
  DIRECT_BUY_EVENT.WISHLIST_ITEM_REMOVED,
  DIRECT_BUY_EVENT.SHOP_BUTTON_CLICKED,
  DIRECT_BUY_EVENT.EMPTY_STATE,
  DIRECT_BUY_EVENT.VIEW_WISHLIST,
  DIRECT_BUY_EVENT.SHOW_NOTIFY_STOCK_BUTTON,
  DIRECT_BUY_EVENT.NOTIFY_STOCK_BUTTON_CLICKED,
  DIRECT_BUY_EVENT.CLOSE_STOCK_OUT_MODAL,
  DIRECT_BUY_EVENT.REMOVE_AND_PAY_STOCK_OUT_MODAL,
  DIRECT_BUY_EVENT.SHOW_STOCK_OUT_MODAL,
  DIRECT_BUY_EVENT.LOOK_AND_LIKE_BANNER_CLICKED,
  DIRECT_BUY_EVENT.SHOP_EDIT_SHIPPING_INFO,
  DIRECT_BUY_EVENT.AFTER_CHECKOUT_ACCESS_GRANTED,
  DIRECT_BUY_EVENT.REDIRECT_TO_CATEGORIES_FROM_CYL,
  DIRECT_BUY_EVENT.ANCHOR_ITEM_LIST,
];

DIRECT_BUY_ACTIONS.forEach(action => {
  eventManager.subscribe(action as DomainEvent, async payload => {
    if (DIRECT_BUY_EVENTS_TO_SEND_DATA.includes(action)) {
      const {id, segment} = await getUserData();
      payload.store = await getStore();
      payload.userId = id;
      payload.segment = segment;
    }

    emitUserEvent({
      event: action,
      eventCategory: 'shop',
      ...payload,
    });
  });
});

APP_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    emitUserEvent({
      event: action,
      eventCategory: 'app',
      ...(await getUserParams()),
      ...payload,
    });
  });
});

PROMO_CODE_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    emitUserEvent({
      event: action,
      eventCategory: 'ecommerce',
      ...(await getUserParams()),
      ...payload,
    });
  });
});

PAY_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    emitUserEvent({
      event: action,
      eventCategory: 'ecommerce',
      ...(await getUserParams()),
      ...payload,
    });
  });
});

eventManager.subscribe(UserEvent.USER_LOADED, user => initUserData(user as UserDataFields));
eventManager.subscribe(UserEvent.LOGOUT, () => cleanUserData());
